.permission-switchboard-container .form-check {
    position: relative;
    flex-direction: row-reverse;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--a8-grey);
    padding-left: 0;
    margin-top: 0.5rem;
}

.permission-switchboard-container>* .form-switch .form-check-input {
    align-self: flex-start;
    border-color: var(--blushing-salmon);
    font-size: 25px;
    display: inline-block;
}

.permission-switchboard-container>* .form-check-input:checked {
    background-color: var(--blushing-salmon);
}

.permission-switchboard-container>* .form-check-label {
    display: inline-block;
    align-self: flex-end;
}

.permission-switchboard-container hr {
    margin: 0;
    padding: 0;
}

.permission-switchboard-container h3 {
    font-size: larger;
}

.permission-switchboard-container h4 {
    font-size: large;
}

.permission-switchboard-container p {
    font-size: medium;
}