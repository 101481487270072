#public-service-level-agreement-page {
  display: flex;
  min-height: calc(100vh - 3rem);
  flex-direction: column;
}

#public-service-level-agreement-page .w-100 li {
  margin-left: 1rem;
}

#public-service-level-agreement-page .footer-container {
  margin-top: auto;
}