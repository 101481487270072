@font-face {
    font-family: "Proxima Nova";
    src: url("/fonts/Proxima Nova Font.otf");
}


/* very official marshall colors*/

:root {
    /* custom vars */
    --ripe-banana: #FFF482;
    --sand-yellow: #F9EC74;
    --endurance-blue: #141438;
    --physics-white: #F6F6F6;
    --almost-black: #030318;
    --action-blue: #363695;
    --deep-sea-blue: #285A77;
    --a8-grey: #A8A8A8;
    --stormy-passion: #C56360;
    --blushing-salmon: #E95252;
    --coral-red: #FA3E3E;
    --action-salmon: #BB2D3B;
    --prosper-green: #30CB71;
    --regular-white: #FFFFFF;
    --gizmo-grey: #90909B;
    --quicksight-grey: #F5F5F5;
    --quicksight-grey-active: #dcdcdc;
    --highlight-color: #FBDDDD;
    --highlight-color-dark: #F7CCCC;
    /* bootstrap re-definition */
    --bs-dropdown-link-hover-bg: #e9ecef;
    /* standard vars */
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: #6c757d;
    --gray-dark: #343a40;
    --primary: var(--almost-black);
    --secondary: var(--gizmo-grey);
    --success: var(--prosper-green);
    --bs-success: var(--prosper-green);
    --bs-success-rgb: 48, 203, 113;
    --info: var(--cyan);
    --warning: var(--yellow);
    --danger: var(--coral-red);
    --bs-danger: var(--coral-red);
    --bs-danger-rgb: 250, 62, 62;
    --light: #f8f9fa;
    --dark: var(--gray-dark);
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: "Proxima Nova", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

/* modal variables */
.modal {
    --bs-modal-header-padding: 0.5rem 0.5rem;
}

/* modal close buttons */
:root .modal-header .btn-close {
    margin-right: -0.125rem;
}

/* some color classes */

.color-alert {
    color: var(--alert);
}

.color-gizmo-grey {
    color: var(--gizmo-grey);
}


/* some general stuff */

.spinner-border {
    margin: 0 7px 0 12px;
    padding: 0 12px 0 12px;
}

:root .ma-btn {
    background-color: var(--endurance-blue);
    color: var(--regular-white);
    border-color: var(--almost-black);
}

:root .ma-btn:hover {
    background-color: var(--a8-grey);
    color: var(--regular-white);
    border-color: var(--almost-black);
}

:root .ma-link {
    color: var(--blushing-salmon);
    text-decoration: none;
    transition: all 0.2s linear;
}

:root .ma-link:hover {
    color: var(--action-salmon);
    cursor: pointer;
}

:root .ma-icon {
    font-family: Pe-icon-7-stroke;
}

.text-blushing-salmon {
    color: var(--blushing-salmon);
}

.text-gizmo-grey {
    color: var(--gizmo-grey);
}

.text-a8-grey {
    color: var(--a8-grey);
}

.text-coral-red {
    color: var(--coral-red);
}

.text-almost-black {
    color: var(--almost-black);
}

.text-endurance-blue {
    color: var(--endurance-blue);
}

.text-action-blue {
    color: var(--action-blue);
}

.text-deep-sea-blue {
    color: #285A77;
}


/* spacing in the DCA instructions */
.pad-child-list-elements li{
    margin-bottom: 0.5rem;
}

/* some general stuff */

.display-grid {
    display: grid
}

.spinner-border {
    margin: 0 7px 0 12px;
    padding: 0 12px 0 12px;
}

.font-weight-bold {
    font-weight: bold;
}

.bg-black,
.black-background {
    background-color: var(--almost-black);
}

.bg-white,
.white-background {
    background-color: white;
}

.bg-transparent {
    background-color: rgba(0,0,0,0);
}

.bg-quicksight-grey,
.quicksights-grey-background {
    background-color: var(--quicksight-grey);
}

.bg-quicksight-active-grey,
.quicksights-active-grey-background {
    background-color: var(--quicksight-grey-active);
}

.bg-a8-grey,
.a8-grey-background {
    background-color: var(--a8-grey);
}

.bg-coral-red,
.coral-red-background {
    background-color: var(--coral-red);
}
.bg-blushing-salmon,
.blushing-salmon-background {
    background-color: var(--blushing-salmon);
}

:root .dropdown-menu,
:root .dropdown-menu[data-bs-popper] {
    margin-top: -0.5rem;
}

.title-button-container {
    text-align: right;
}

:root input:focus {
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    outline: none;
    border-color: var(--a8-grey);
}


/* overwrite what is in bootstrap */

.btn-primary {
    background-color: var(--endurance-blue);
    border-color: var(--endurance-blue);
}

.btn-primary:hover {
    background-color: var(--action-blue);
    border-color: var(--action-blue);
}

.btn-link {
    color: var(--endurance-blue);
}

.btn-link:hover {
    color: var(--action-blue);
}

.btn-action {
    background-color: var(--coral-red);
    border-color: var(--coral-red);
}

.btn-action:hover {
    background-color: var(--blushing-salmon);
    border-color: var(--blushing-salmon);
}


/* fix / simplify the nav dropdown */

:root [data-rr-ui-dropdown-item]:not(:last-child) {
    border-bottom: 1px solid var(--a8-grey);
}


/* fix radio button color */
.form-switch>.form-check-input:not(:checked) {
    // sets middle knob color to blushing salmon when focused but not checked
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23E95252'/%3e%3c/svg%3e");
}

.form-check-input:checked {
    background-color: var(--blushing-salmon);
    border-color: var(--blushing-salmon);
}

/*  filter panes for tables*/

:root .filter-pane {
    overflow-y: scroll;
}


:root .filter-pane .mock-accordion::after {
    display: none;
}

:root .filter-pane .accordion-item {
    margin-bottom: 1rem;
    border-radius: 0.345rem;
    border: var(--a8-grey) solid 1px;
}

:root .filter-pane .accordion-header .accordion-button {
    height: 2rem;
}

:root .filter-pane .accordion {
    border: none;
}

:root .filter-pane .accordion-body {
    overflow-y: scroll;
}

/* disabled button color */
/* Custom styling for "you can't upload" tooltip */
.disabled-button-wrapper {
    display: inline-block;
    height: 2.5rem;
    min-width: 10rem;
}

.upload-tooltip div.tooltip-arrow {
    --bs-tooltip-bg: var(--a8-grey);
}

.upload-tooltip div.tooltip-inner,
.btn-primary[disabled] {
    background-color: var(--a8-grey);
    border-color: var(--a8-grey);
    color: #212529;
}


/* when you want more black... */

:root .keep-almost-black,
:root .keep-almost-black:hover {
    color: #212529;
}

.form-check,
.form-check>input,
.form-check-input,
.form-check>label {
    cursor: pointer;
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.clickable {
    cursor: pointer;
}

.product-field {
    font-style: italic;
    color: var(--gizmo-grey);
}

.example-url {
    color: var(--green);
    /* color: #147045; */
}

.url-fragment {
    color: var(--coral-red);
}

.capitalize {
    text-transform: capitalize;
}

// prevent chevron color from changing on active
#root .accordion-button:not(.collapsed)::after {
    background-image: var(--bs-accordion-btn-icon);
}

#root .accordion-button.collapsed {
    box-shadow: none;
}

#root .accordion-button:not(.collapsed) {
    color: var(--almost-black);
    background-color: var(--quicksight-grey);
    box-shadow: none;
}

/* Used for when the "(Select All)" checkbox is in a "mixed" state */
.checkbox-grey-bg input[type=checkbox] {
    background: var(--quicksight-grey-active);
}

/* dropdowns */

#root .dropdown-item.active {
    text-decoration: none;
    background-color: var(--quicksight-grey-active);
}

.dropdown-menu {
    --bs-dropdown-link-active-bg: var(--quicksight-grey-active);
}

#user-info-area>div {
    transition: background-color 100ms linear, color 100ms linear;
}


/* buttons */

#root {
    :not(.btn-check)+.btn:active,
    .btn-check:checked+.btn,
    .btn:first-child:active,
    .btn.active,
    .btn.show {
        color: var(--bs-btn-active-color);
        background-color: var(--endurance-blue);
        border-color: var(--endurance-blue);
    }
}


/* sizing */

#root .mx-100 {
    max-width: 100%;
}

/* leftNav highlights */
#root .highlight {
    font-weight: 600;
    background: var(--highlight-color);
    border-radius: 0.5rem;
}

#root .leftNavActiveParent .accordion-button {
    font-weight: 700;
}

/* modal close "x" */
.modal .btn-close:focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem var(--highlight-color);
    opacity: 1;
}


/* bootstrap tooltip adjustment */
.tooltip-inner {
    max-width: 480px;
}

/* hide stuff... */
.stealth-text {
    height: 0;
    width: 0;
    overflow: hidden;
}

/* scroll bars.. */
:root ::-webkit-scrollbar {
    background: rgba(0, 0, 0, 0);
    width: 6px;
}

:root ::-webkit-scrollbar-thumb {
    background: var(--a8-grey);
    border-radius: 3px;
}

/* button link */

.ma-btn-link {
    background: none !important;
    border: none;
    padding: 0 !important;
    /*optional*/
    /*input has OS specific font-family*/
    text-decoration: underline;
    cursor: pointer;
}


/* box-shadow */

textarea.form-control:focus,
.btn-close:focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem var(--highlight-color);
    border-color: var(--highlight-color);
    opacity: 1;
}

.alert-dismissible .btn-close {
    transition: background-color 300ms linear;
}
.alert-dismissible .btn-close:focus{
    box-shadow: none;
    background-color: var(--highlight-color);
}

/* for error pages */
.error-message-container {
    font-size: x-large;
    margin-left: 25%;
}

.asyncButton.loading{
    color: var(--almost-black);
    border-color: var(--a8-grey);
    background-color: var(--a8-grey);
}
.asyncButton.completed{
    color: var(--almost-black);
    border-color: var(--prosper-green);
    background-color: var(--prosper-green);
}
.asyncButton.failed{
    border-color: var(--coral-red);
    background-color: var(--coral-red);
}
