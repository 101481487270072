body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: var(--almost-black);
    ;
}

pre {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif, monospace;

}
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
    height: 100vh;
}

@font-face {
    font-family: "Proxima Nova";
    src: url('../src/fonts/Proxima Nova Font.otf');
}

.page {
    padding: 45px 7.5rem 30px 7.5rem;
    width: 100%;
    max-height: calc(100vh - 45px);
    overflow-y: auto;
}

.page.col-10 {
    width: 83%;
}

.page.col-8 {
    width: 66%;
}

.page.col-6 {
    width: 50%;
}

.page.col-4 {
    width: 33%;
}

.under-nav {
    display: block;
    height: 45px;
    width: calc(100vw - 1rem);
    color: #FFF;
    background: #FFF;
}

.flex {
    display: flex;
}

.alert {
    width: 100%;
}
