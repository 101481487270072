#left-nav {
    position: fixed;
    top: 45px;
    bottom: 0;
    left: 0;
    z-index: 100;
    background: #fff;
    height: 100%;
    -webkit-user-select: none; /* Chrome/Safari */ 
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+ */
    user-select: none;
}

#left-nav-content {
    transition: width linear 220ms;
    overflow-x: hidden;
    padding-bottom: 2rem;
}

#left-nav .nav {
    padding: 1rem;
    border-right: 1px #888 solid;
    height: 100%;
    overflow-y: auto;
}

#left-nav .sidebar-collapsed {
    width: 0.625rem;
    height: 100%;
    border-right: 1px #888 solid;
}

#left-nav .accordion-body {
    padding: 0px;
}

#left-nav .sidebar-icon {
    color: var(--physics-white);
    background-color: var(--gizmo-grey);
    border: 1px var(--a8-grey) solid;
    border-radius: 100%;
    position: absolute;
    top: 1.75rem;
    z-index: 2;
    left: calc(100% - 14px)
}

#left-nav .sidebar-icon:hover {
    cursor: pointer;
}

.leftNavbar,
#left-nav .sidebar-expanded {
    width: 330px;
    height: 100%;
}

#left-nav .accordion-item:first-of-type .accordion-button,
#left-nav .accordion-button,
#left-nav .accordion-item {
    border: 0;
    background: none;
    outline: none;
    box-shadow: none;
}

#left-nav .accordion:last-child {
    margin-bottom: 1rem;
}

#left-nav .accordion-item .accordion-collapse .accordion-button {
    color: var(--almost-black);
}

#left-nav .nav-item {
    margin-bottom: 20px
}

#left-nav .accordion-button::after {
    background-size: 1em;
    background-position: center;
}

#left-nav .left-nav-section-header .left-nav-section-header-label{
    font-weight: 700;
    text-transform: uppercase;
    color: var(--blushing-salmon);
    margin-top: 1.25rem;
    margin-bottom: 0.75rem;
    margin-left: 0.625rem;
}

#left-nav .sidebar-link {
    transition: background 100ms linear;
    margin-top: 0.125rem;
    border-radius: 0.5rem;
    padding: 5px 20px;
    color: var(--almost-black);
    text-decoration: none;
    text-transform: capitalize;
}

#left-nav .accordion-button.collapsed {
    background: var(--physics-white);
}

#left-nav .accordion .sidebar-link {
    padding-left: 60px;
}

#left-nav .sidebar-link:hover {
    background: var(--highlight-color);
}

#left-nav .sidebar-link:hover:focus {
    background: var(--highlight-color-dark);
}


/*
 * Using pe icon's for side nav
 * See /public/css/pe-icons/pe-icon-7-stroke.css 
 */

#left-nav .accordion-button i.pe-7s-accordion {
    width: 30px;
    text-align: center;
}

#left-nav .accordion-button i.pe-7s-accordion.icon-availability::before {
    content: "\e66c";
}

#left-nav .accordion-button i.pe-7s-accordion.icon-pricing::before {
    content: "\e60c";
}

#left-nav .accordion-button i.pe-7s-accordion.icon-reviews::before {
    content: "\e611";
}

#left-nav .accordion-button i.pe-7s-accordion.icon-content::before {
    content: "\e66f";
}

#left-nav .accordion-button i.pe-7s-accordion.icon-marketplace::before {
    content: "\e691";
}

#left-nav .accordion-button i.pe-7s-accordion.icon-sales::before {
    content: "\e6c0";
}

#left-nav .accordion-button i.pe-7s-accordion.icon-promotions::before {
    content: "\e6c3";
}

#left-nav .accordion-button i.pe-7s-accordion.icon-advertising::before {
    content: "\e612";
}

#left-nav i.pe-7s-accordion.icon-overview::before {
    content: "\e64e";
    margin-right: 0.5rem;
}

#left-nav #sidebar-wrapper {
    -webkit-transition: margin .25s ease-out;
    -moz-transition: margin .25s ease-out;
    -o-transition: margin .25s ease-out;
    transition: margin .25s ease-out;
}

#left-nav #sidebar-wrapper .sidebar-heading {
    padding: 0.875rem 1.25rem;
    font-size: 1.2rem;
}

#left-nav #page-content-wrapper {
    min-width: 0;
    width: 100%;
}

#left-nav .modal {
    z-index: 9999999;
}

.leftnav-empty{
    color: var(--a8-grey);
    margin-top: 2.75rem;
    text-align: center;
}