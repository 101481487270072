.page-title-header {
    padding-bottom: 32px;
    position: relative;
}

.page-title-header h3 {
    position: relative;
    top: 0.25rem;
}

.page-title-header .return-link {
    position: absolute;
    bottom: 0.5rem;
    left: 0.75rem;
}