.page#home-page {
    margin-left: 340px;
    padding: 45px 7.5rem 30px 0;
    width: calc(100% - 340px);
    position: absolute;
}

#home-page>.container:not(:first-child) {
    margin-top: 1rem;
}

#home-page .notificationBlock {
    margin-bottom: 2.5rem;
}

#home-page .notificationBlock .card {
    height: 18rem;
}

#home-page .productsBlock .card {
    height: 10rem;
}

#home-page .card .spinner-border {
    margin: auto;
}

.card-table-title-text {
    color: var(--blushing-salmon);
    bottom: 15px;
    position: absolute;
    text-transform: uppercase;
}

.card-item-count-title {
    text-transform: uppercase;
}
