.card.notification-card.disabled {
    background-color: rgba(168, 168, 168, 0.2);
}

.notication-card {
    min-height: 19rem;
    max-height: 19rem;
    padding: 0;
}

.notication-card table {
    margin: 0;
}

.card.notification-card-failed p,
.card.notification-card-failed svg {
    margin: auto;
}

.notication-card-no-data-text {
    margin: auto;
    width: 100%;
}

.notication-card thead {
    background-color: var(--regular-white);
    position: sticky;
    top: 0;
}

/* remove trailing border from last row in table (override _tables.scss) */
.notification-card .table > :not(caption) > tr:last-child > td {
    border-bottom: none;
}