.search-sku-nav-dropdown {
    left: 152px;
}

.search-sku-nav-dropdown .dropdown-toggle::after {
    display: none;
}

#search-sku-nav-dropdown .search-sku-input {
    top: 0;
    transform: scale(0.8);
    left: -24px;
    border: none !important;
}

.search-sku-nav-dropdown>div.dropdown-menu.show {
    top: 0;
    z-index: 999;
    overflow-y: scroll;
    max-height: 82vh;
    display: block !important;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    visibility: visible !important;
    opacity: 1 !important;
    transform: translateY(50px);
    height: auto;
    padding-right: 0.25rem;
}

.search-sku-nav-dropdown>div.dropdown-menu .dropdown-item{
    padding-right: 0.25rem;
}
.search-sku-nav-dropdown>div.dropdown-menu.show .alert {
    margin-bottom: 0;
}


.search-sku-nav-dropdown>div.dropdown-menu p {
    margin-bottom: 0 !important;
}

.search-sku-nav-dropdown>div.dropdown-menu:empty {
    display: none !important;
    border: 0 !important;
}

.search-sku-nav-dropdown .search-sku-result .ellipsis {
    display: inline-block;
    max-width: 20rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    line-height: 1.2rem;
}

.search-sku-nav-dropdown .sku-detail-item-count-information-item:hover {
    background-color: var(--quicksight-grey-active);
    color: initial;
    font-weight: 400;
    color: var(--black);
}

.search-sku-nav-dropdown .sku-result-manufacturer_sku {
    font-size: 1.25rem;
    font-weight: 700;
    color: var(--blushing-salmon);
}

.search-sku-nav-dropdown ::-webkit-scrollbar-track-piece:start {
    background-color: inherit;
}

.search-sku-nav-dropdown ::-webkit-scrollbar-track-piece:end {
    background-color: inherit;
}

.search-sku-nav-dropdown .tracked-icon,
.sku-detail-product-status {
    margin-top: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
}

.search-sku-nav-dropdown .tracked-icon {
    float: right;
    height: 0.5rem;
    width: 0.5rem !important;
    border-radius: 0.5rem;
    margin-left: 0;
    margin-top: 0;
    padding: 0;
    right: 0.25rem;
    position: relative;
}

.sku-search-product-status-container {
  right: -1rem
}