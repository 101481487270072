/* upload and download buttons*/

#item-administration-page .add-edit-button {
    margin-right: 1rem;
}

#item-administration-page .add-edit-button>svg {
    height: 22px;
    width: 22px;
}

#item-administration-page .not-allowed-to-use:hover {
    cursor: crosshair;
}


/* buttons, filters, selectors*/

#item-administration-page .filter-dropdown.dropdown-toggle.btn.btn-primary {
    text-align: left;
    background-color: var(--endurance-blue);
    box-shadow: none;
    margin: 5px 0;
}

#item-administration-page .filter-dropdown::after {
    display: none;
}

#item-administration-page .dropdown.show .dropdown-toggle svg.filter-chevron {
    transform: rotate(180deg);
    transition: transform 0.15s linear;
}

#item-administration-page .accordion-container {
    overflow-y: scroll;
    max-height: calc(100vh - 20rem);
    padding-right: 0.25rem;
}

#item-administration-page .accordion-body {
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
}

/*
Searching styles
*/

#item-administration-page .search-text {
    background-color: #FFF;
    border-color: var(--a8-grey);
    border-right: 0;
}

#item-administration-page .item-search-input,
#item-administration-page .item-search-input:focus {
    border-left: 0;
    border-color: var(--a8-grey);
}

:root #item-administration-page input:focus {
    outline: none;
    outline-width: 0;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
}


/* table */

#item-administration-page .ma-table-container {
    height: 39rem;
}

#item-administration-page .ma-table-row {
    height: 2.5rem;
    line-height: 2.5rem;
}

#item-administration-page .ma-table-cell div,
#item-administration-page .ma-table-cell {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

#item-administration-page table {
    table-layout: fixed;
}

#item-administration-page tbody tr:hover {
    background-color: var(--bs-dropdown-link-hover-bg);
}

#item-administration-page td {
    word-wrap: break-word;
    white-space: normal;
}

[id^=unfilled-values-tooltip] div {
    text-align: left;
}

#download-message-status-text {
    display: block;
    margin-top: 3rem;
    clear: both;
}

#item-administration-page .tracked-column{
    width: 5rem;
}

#item-administration-page .downloadFileStatus{
    position: absolute;
    bottom: 0.5rem;
    right: 0.75rem;
}

#item-administration-page .text-gizmo-grey path{
    fill: var(--gizmo-grey);
}