#UsersNewPage .role-description {
    margin-left: 1.5rem;
}

#UsersNewPage .info-pane {
  padding-right: 2rem;
}

#UsersNewPage .create-user-button {
  width: 10rem;
  height: 3rem;
}
