.row.right-pane-header h3 {
  margin-left: -0.75rem;
}

.col-10.data-collection-account button {
  background: rgba(0,0,0,0);
  border: 1px solid rgba(0,0,0,0);
}

.col-10.data-collection-account button:hover {
  background: rgba(0,0,0,0);
  border: 1px solid rgba(0,0,0,0);
}

.col-10.data-collection-account button.btn-primary:hover svg path{
  fill: var(--action-blue);
}

.col-10.data-collection-account button.btn-danger:hover svg path{
  fill: var(--action-salmon);
}
