/* upload and download buttons*/

#sov-administration-page .add-edit-button {
  margin-right: 1rem;
}

#sov-administration-page .add-edit-button>svg {
  height: 22px;
  width: 22px;
}

#sov-administration-page .not-allowed-to-use:hover {
  cursor: crosshair;
}

#sov-administration-page .accordion-body {
  max-height: 9rem;
  padding-top: 0.1rem;
}

#sov-administration-page .search-input-group {
  margin: 0.5rem auto;
}

#sov-administration-page .filter-form {
  padding: 1rem;
}

#sov-administration-page table {
  table-layout: fixed;
}

#sov-administration-page .ma-table-container {
  height: 32rem;
}

#sov-administration-page .ma-table-row {
  line-height: 2rem;
  height: 2rem;
}

#sov-administration-page .ma-table-cell div,
#sov-administration-page .ma-table-cell {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

#sov-administration-page .filter-form .table-filter-status-dropdown{
  text-transform: capitalize;
}