.retailer-url-formatting-page .PageContent {
  border-top: 1px solid var(--gizmo-grey);
  padding-top: 1rem;
}

.retailer-url-formatting-page .PageContent a {
  color: var(--blushing-salmon);
}

.retailer-url-formatting-page ol {
  margin-left: 3rem;
}
