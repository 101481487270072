/* modal styles */

.upload-term-modal-container .modal-dialog {
    max-width: 1200px;
    max-height: calc(100% - 3rem);
    position: fixed;
    top: 0.5rem;
    left: calc(50vw - 600px);
    overflow-x: hidden;
    overflow-y: auto;
    transition: height 0.3s linear;
}

.upload-term-modal-container.summary-modal .modal-dialog {
    height: calc(100% - 3rem);
}

.upload-term-modal-container .modal-content {
    height: 100%;
}

.upload-term-modal-container #terms-upload-modal-submit-button {
    min-width: 90px;
}

.upload-term-modal-container #terms-upload-modal-submit-button.disabled {
    background-color: var(--gizmo-grey);
}

.upload-term-modal-container .modal-body {
    overflow-x: hidden;
    overflow-y: auto;
    padding: 1rem;
}

.upload-term-modal-container .modal-footer {
    border-top: 0px;
    margin: 0;
    padding: 0 0.75rem 0.75rem 0.75rem;
}

.upload-term-modal-container hr {
    margin-top: 15px;
    border-top: 2px solid var(--a8-grey);
}


/* dropdown button styles */

.upload-term-modal-container .accordion-item .accordion-button:focus,
.upload-term-modal-container .accordion-item:focus {
    outline: none;
    outline-width: 0;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
}

.upload-term-modal-container .accordion-item,
.upload-term-modal-container .accordion-item:not(:first-of-type) {
    margin-top: 30px;
    border-width: 0.2em;
    border: solid 0.15em var(--a8-grey);
}

.upload-term-modal-container .accordion-item .accordion-button {
    color: inherit;
}

.upload-term-modal-container .accordion-item .accordion-button:not(.collapsed) {
    background-color: var(--quicksight-grey);
}

.upload-item-modal-container .accordion-item .accordion-button:not(.collapsed)::after {
    background-image: var(--bs-accordion-btn-icon);
}


/*modal size*/

.upload-term-modal-container .accordion-body,
.upload-term-modal-container .modal-body,
.upload-term-modal-container .modal-content {
    max-width: 1200px;
    width: 1200px;
}

.upload-term-modal-container .accordion-body{
    margin-right: -0.375rem;
}

.upload-term-modal-container .accordion-body .table {
    width: 100%;
}

.upload-term-modal-container .accordion-body .table td:nth-child(1),
.upload-term-modal-container .accordion-body .table td:nth-child(2) {
    max-width: 160px;
}

.upload-term-modal-container .accordion-body .table td:nth-child(3) {
    max-width: 240px;
}

.upload-term-modal-container .accordion-body .table td:nth-child(4) {
    max-width: 500px;
}

.upload-term-modal-container td{
    overflow: hidden;
    text-overflow: ellipsis;
}

/* accodion body */

.upload-term-modal-container .accordion-body {
    padding: 0;
    min-width: 600px;
    width: calc(100% + 2.25rem);
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: scroll;
}


/* Table */

.upload-term-modal-container .accordion-body table,
.upload-term-modal-container tr {
    text-align: left;
    border-width: 0.02em;
    margin: 0;
}

.upload-term-modal-container .accordion-body table thead {
    border-bottom: 0;
    position: sticky;
}

.upload-term-modal-container tbody tr,
.upload-term-modal-container tbody th {
    height: 60px;
    max-height: 60px;
    white-space: break-spaces;
    word-wrap: break-word;
}

.apply-changes-button {
    width: 10rem;
}