#root .table-pagination {
    .btn {
        border: none;
        margin: 0 4px;
        min-width: 50px;
    }
    .btn-check:checked+.btn,
    :not(.btn-check)+.btn:active,
    .btn:first-child:active,
    .btn.active,
    .btn.show {
        color: var(--bs-btn-active-color);
        background-color: var(--endurance-blue);
        border-color: var(--endurance-blue);
    }
    
    .btn.arrow, .btn.arrow:focus, .btn.arrow:hover:focus {
        background: none;
        color: var(--blushing-salmon);
        font-weight: lighter;
        font-size: 3em; 
        transition: all 0.2s linear;
        margin: 0;
        padding: 0;
        line-height: 20px;
    }
  .btn.active {
    background-color: var(--action-blue);
    opacity: initial;
  }
  .btn.arrow:hover, .btn.arrow:hover:focus, .btn.show {
    background: none;
    color: var(--action-salmon);
  }

  .paginationBlankSpace {
    position: relative;
    width:3.625rem;
    color: var(--gizmo-grey);
    height: 1px;
    margin-top: 0;
  }
}
