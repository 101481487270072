#public-home-page {
  overflow-x: hidden;
  max-width: 100% !important;
}
#public-home-hero-banner {
  background: linear-gradient(90deg, var(--stormy-passion) 0%, var(--sand-yellow) 100%);
  width: 100vw;
  padding-bottom: 2rem;
}

#public-home-hero-banner .banner-card {
  width: 25rem;
  height: 35rem;
  background: #FFFFFF;
  text-align: center;
  padding: 2rem;
  margin-top: 2rem;
}

#public-home-retailers-supported {
  width: min(1200px, 100vw);
}

#public-home-retailers-supported .retailer-card {
  display: inline-block;
  background: #FFFFFF;
  text-align: center;
  padding: 1rem;
  font-weight: 700;
  border: 0;
  font-size: 1.5rem;
}

#public-home-ecomm-central {
  background: var(--quicksight-grey);
  width: 100vw;
  padding-bottom: 3rem;
}