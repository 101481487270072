
.cell.primary { color: #000; }
.cell.secondary { color: #CCC; }

.product-link-button {
  border: none;
  background: rgba(0, 0, 0, 0);
  line-height: 1.5rem;
}

.tracked-icon {
  margin-left: 1.25rem;
  height: 1rem;
  width: 1rem !important;
  border-radius: 0.5rem;
  margin-top:0.75rem;
}

.tracked-icon-active {
  background: var(--success);
}

.tracked-icon-requires.attention {
  background: var(--yellow);
}

.tracked-icon-disabled {
  background: var(--danger);
}

#item-administration-page .ma-table-row .ma-table-cell:last-child{
  width: 5rem;
}