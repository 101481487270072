body .notes-panel.slide-pane__overlay {
  top: 45px;
}

.notes-panel .slide-pane__content,
.notes-panel .slide-pane {
  padding: 0;
  height: calc(100vh - 45px);
  overflow: clip;
}

.notes-panel textarea {
  resize: none;
  outline: none;
}

.notes-header {
  height: 7rem;
}

.notes-panel .notes-product-item-container {
  overflow-y: scroll;
  overflow-x: clip;
  height: calc(100% - 7rem - 13rem)
}

.notes-panel .slide-pane__content .search-input-group {
  margin-left: 0 !important;
}

.notes-panel .retailers-check-box-container {
  flex-direction: row-reverse;
  align-content: space-evenly;
  width: 100%;
  align-items: center;
}


.notes-panel .retailers-check-box-container input {
  float: none;
  margin-left: 1em;
}

.notes-header .note-header-attribute-label {
  min-width: fit-content;
  max-height: 1.5em;
}

.notes-header .note-header-attribute-value>p {
  max-width: fit-content;
}

.notes-panel .notes-panel-new-item-container {
  height: 13rem;
  position: absolute;
  bottom: 0;
}

.note-entry-length-container {
  float: right;
  margin-top: -1.25rem;
  width: 4rem;
  margin-right: 0.5rem;
  font-size: 0.7rem;
}

.notes-panel .down-1-8 {
  position: relative;
  bottom: -0.125rem;
}

.notes-panel .notes-second-row {
  height: 1.5rem;
}

.notes-panel .create-new-note-button{
  position: fixed;
  right: 126px;
}
