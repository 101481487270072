.help-modal .modal-dialog {
    min-width: 40%;
}

.help-modal #ControlTextarea1 {
    resize: none;
}

.help-file-upload-input {
    margin-top: 15px;
}

.help-entry-length-container {
    float: right;
    margin-right: 0.5rem;
    margin-top: -1.25rem;
    width: 5.5rem;
    font-size: 0.7rem;
}

.file-list-item {
    margin-bottom: 2px;
}

.help-buttons>button {
    float: right;
    margin: 0 0 0 7px;
}


.help-modal .help-modal-message-box {
    height: 12rem;
}

.help-modal .alert {
    margin-bottom: 0.2rem;
}

.help-modal .file-size-info-container {
    height: 4rem;
    overflow-y: scroll;
}