.delete-user-modal .modal-content {
}

.delete-user-modal .modal-content input{
  width: 100%;
  margin-top: 0.5rem;
}

.delete-modal .delete-buttons{
  margin-top: 0.5rem;
  text-align: right;
  padding: 0;
}

.delete-modal .delete-buttons>*{
  margin-left: 1rem;
}
