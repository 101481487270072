#org-search-input,
#org-search-input:focus {
    box-shadow: none;
    border-color: #ced4da;
    height: 30px;
}

.org-modal-current-org-name {
    font-weight: bold;
}

.org-modal .search-org {
    background-color: #FFF;
    border-color: var(--a8-grey);
    border-right: 0;
}

.org-modal .org-search-input,
.org-modal .org-search-input:focus {
    border-left: 0;
    border-color: var(--a8-grey);
}

.org-modal .modal-dialog .org-option-list {
    max-height: 52.5vh;
    overflow-y: scroll;
}
