#embeddingContainer {
    position: absolute;
    margin-left: 10px;
    height: calc(100vh - 45px);
    width: calc(100% - 10px);
    padding: 0px;
    top: inherit;
    overflow: hidden;
    background-color: var(--quicksight-grey);
}

#embeddingContainer>iframe {
    height: 100%;
    width: 100%;
}

#embeddingContainer.invisible {
    z-index: -1;
    top: 0;
}
