#reset-container {
    height: calc(100% - 45px);
    width: 100%;
    overflow: hidden;
}

#reset-container input {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    box-shadow: none;
}

