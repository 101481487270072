#UsersEditPage .role-description {
    margin-left: 1.5rem;
}

#UsersEditPage .info-pane {
    padding-right: 2rem;
}

#UsersEditPage .delete-user-button {
    margin-right: 1rem;
}

#UsersEditPage .fit-spinner {
    transform: scale(0.8);
    height: 1.75rem;
    margin-top: -0.25rem;
    justify-content: center;
    display: flex;
}


/* may be moving away.. */

#UsersEditPage .modal-bg {
    z-index: 101;
    position: fixed;
    top: 0;
    height: 100vh;
    left: 0;
    width: 100vw;
    background: rgba(127, 127, 127, 50);
    overflow: none;
}

#UsersEditPage .delete-user-popup {
    z-index: 101;
    position: fixed;
    top: 20vh;
    min-height: 30vh;
    left: 35vw;
    width: 30vw;
    background: var(--physics-white);
    padding: 1em;
}

#UsersEditPage .save-user-button {
    width: 4rem;
}