.search-input-group .search-input-icon {
    padding-right: 0;
}

.search-input-group .search-input-label {
    background-color: #FFF;
    border: none;
}

.search-input-group .search-input-control {
    border: none;
    padding-left: 0;
}

.search-input-group .search-input-control:focus {
    box-shadow: none;
    outline: none;
    border: none;
}

/* webkit: hide the "x" to clear - it doesn't always work
   (bug in browser)
*/
.search-input-group input[type="search"]::-webkit-search-cancel-button{
  display: none;
}

.search-input-label.bg-disabled {
    background-color: rgb(233, 236, 239);
}
