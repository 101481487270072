.notifications-modal .modal-dialog {
    min-width: 40%;
}

.notifications-buttons>button {
    float: right;
    margin: 0 0 0 7px;
}


/* for the navbar */

.notification-count-container {
    background-color: var(--coral-red);
    color: var(--physics-white);
    border-radius: 1rem;
    margin-left: 7.5px;
    min-width: 30px;
    padding: 2px;
    float: right;
    line-height: 20px;
}


/* Colored Dots */

.notifications-modal span.dot {
    position: relative;
    top: 0.2rem;
    display: inline-block;
    height: 0.8rem;
    width: 0.8rem;
    margin: 0em 1rem;
    border-color: var(--gray);
    border-width: 1px;
    border-radius: 50%;
}


/* nothing special.. but it is here if we need it */

.notifications-modal hr {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}

.notification-message {}

.notification-acknowledgement {
    float: right;
    margin-right: 1rem;
}

.navbar-spinner-container {
    display: inline-block;
    transform: scale(0.5);
    min-width: 30px;
    float: right;
    line-height: 1rem;
    position: relative;
    top: -0.25rem;
}


/* modal containers */

.notifications-modal-table-container {
    max-height: 24rem;
    overflow-y: scroll;
}

/* remove trailing border from last row in table (override _tables.scss) */
.notifications-modal .table > :not(caption) > tr:last-child > td {
    border-bottom: none;
}