.book-a-demo-modal .modal-dialog {
    min-width: 40%;
}

.book-a-demo-modal #ControlTextarea1 {
    resize: none;
}

.book-a-demo-entry-length-container {
    float: right;
    margin-right: 0.5rem;
    margin-top: -1.25rem;
    width: 5.5rem;
    font-size: 0.7rem;
}

.book-a-demo-buttons>button {
    float: right;
    margin: 0 0 0 7px;
}


.book-a-demo-modal .book-a-demo-modal-message-box:focus {
    box-shadow: none;
    border-color: var(--a8-grey);
}

.book-a-demo-modal .alert {
    margin-bottom: 0.2rem;
}

.bookADemoInputContainer {
    height: 5em;
}