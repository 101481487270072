.user-administration-page .form-control:focus {
    border: 1px solid #ced4da;
    /* color taken from bootstrap */
    outline: none;
    outline-width: 0;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
}

.user-administration-user-navigate-container {
    margin-bottom: 30px;
    padding-bottom: 30px;
}

.user-administration-user-navigate-container>a {
    text-decoration: none;
}

.user-administration-user-search>span {
    display: flex;
}

#root .user-administration-page span>strong {
    margin-right: 0.25em;
}

.user-administration-save-button {
    background-color: var(--endurance-blue);
    color: white;
    border: 1px var(--a8-grey);
    margin: 10px 0 40px 0;
}

.user-administration-save-button:focus {
    background-color: var(--endurance-blue);
}

.user-administration-form-title {
    font-size: large;
}

#root .user-administration-form-container form div input.form-control {
    width: 40%;
}

.user-administration-page .user-search-text.input-group-text {
    background-color: var(--normal-white);
}

.user-administration-page .search-text {
    background-color: #FFF;
    border-color: var(--a8-grey);
    border-right: 0;
}

.user-administration-page .alert {
    width: fit-content;
}

.user-administration-page .user-info-page {
    padding-right: 2rem;
}

.user-administration-page table th:nth-child(3),
.user-administration-page table th:nth-child(4) {
    text-align: center;
}

.user-administration-page .ma-table-container {
    height: 32rem
}

.user-administration-page .ma-table-cell {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.user-administration-page .name-email-cell {
    width: 350px;
}

.user-administration-page .role-permissions-cell {
    width: calc(75vw - 720px);
    overflow-wrap: break-word;
    position: relative;
    overflow: hidden;
}

.user-administration-page .role-permissions-cell .cell {
    width: calc(100%);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.user-administration-page tbody tr:hover {
    background-color: var(--bs-dropdown-link-hover-bg);
}

[id^=unfilled-values-tooltip] div {
    text-align: left;
}

.user-administration-page .last-login-cell,
.user-administration-page .actions-cell {
    width: 110px;
}

.user-administration-page a.edit-user-button.disabled {
    pointer-events: none;
    color: var(--a8-grey);
}