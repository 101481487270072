.card-table-title-text {
    bottom: 30px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    position: relative;
    top: 0;
}

.side-margins-for-table {
    padding-left: 1rem;
    padding-right: 1rem;
    overflow-y: scroll;
    height: calc(100% - 2rem);
}

.card-table .table-responsive thead {
    border-bottom: 2px solid var(--gizmo-grey);
}

.card-table .table-responsive tr:last-child {
    border-bottom: 0 solid rgba(0, 0, 0, 0);
}

.card-table-value-nodata {
    margin: auto;
    width: 100%;
    font-size: x-large;
}

.card-table .card-footer {
    background-color: inherit;
    border: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}

.card-table .card-footer svg.scrollIcon {
    position: absolute;
    right: 0;
    top: -2rem;
    z-index: 10;
    background: #E7E7E7;
    border-radius: 50%;
}

.card-table .card-table .card-table-row .card-table-cell-container:not(:last-child) {
    overflow: hidden;
    max-width: 12rem;
    display: inline-block;
    height: 1.5rem;
    line-height: 1.5rem;
}