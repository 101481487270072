.card-item-count-value {
  font-size: 5rem;
  font-weight: 700;
  padding: 0;
  line-height: 5rem;
}

.card-item-count-value,
.card-item-count-title,
.card-item-count-subtitle {
  margin: auto;
  width: 100%;
}

.card-item-count .card-footer{
  background-color: inherit;
  border: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
