.login-page {
    height: calc(100% - 45px);
    width: 100%;
    overflow: hidden;
}

.login-page input {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    box-shadow: none;
}

.login-page .reset-link {
    color: var(--almost-black);
    text-decoration: none;
    margin-left: 30px;
}

.login-page .login-area {
    display: inline-block;
    width: 100%;
    text-align: center;
}

.login-page .btn,
.reset-page .btn {
    padding: 0.5rem 4rem;
    margin-top: 1.5rem;
}

.login-page .btn {
    width: 14.8rem;
}

.login-page .login-label>p {
    margin-bottom: 0;
}

.forced-password-page {
    margin: auto;
}