.settings-page span strong {
    margin-right: 0.25em;
}

.settings-form-title {
    font-size: large;
}

.settings-page li {
    list-style-type: none;
}

.settings-page ul {
    padding: 0;
}

.settings-page .version-footer {
    width: 100%;
    text-align: right;
    color: var(--gizmo-grey);
}