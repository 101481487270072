#loading-page {
  height: calc(100vh - 45px);
}

#loading-page .spinner-border {
  height: 100px;
  width: 100px;
  top: 40%;
  position: relative;
}

#loading-page .loading-page-text {
  position:relative;
  top: calc(40% + 1rem);
}

#loading-page .loading-page-text .ellipsis-wrapper {
  margin-left: 0.375rem;
  display: inline-block;
  width: 2rem;
  position:relative;
}

#loading-page .loading-page-text .ellipsis {
  display: inline-block;
  font-size: 1.875rem;
}

#loading-page .loading-page-text .ellipsis:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4,end) 3000ms infinite;      
  animation: ellipsis steps(4,end) 3000ms infinite;
  content: "\2026"; /* ascii code for the ellipsis character */
  width: 0;
}

@keyframes ellipsis {
  to {
    width: 1.5rem;    
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.5rem;    
  }
}